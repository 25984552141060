import { ChangeEvent } from "react";

type Props = {
    name: string,
    type: string,
    placeholder?: undefined | string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value?: number,
    required?: boolean,
    step?: string,
    max?: string | undefined,
    error?: null | undefined | string,
}

export const TextInput = ({error, name, type, placeholder, onChange, value, required, max, step} : Props) => {
    return (
        <div>        
            <input
            required={required}
            className="shadow appearance-none borderbe rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name={name}
            type={type}
            max={max}
            min="0"
            step={step}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            ></input>
        </div>
    )
}