import { ChangeEvent, FormEvent, useState } from "react";
import { fuelFormulaRisk, fuelFormulaSafe } from "../constants/formula";
import { FuelForm } from "../types";

const FUEL_DEFAULT_VALUES = {
    durationHours: 0, 
    durationMinutes: 0,
    perLap: 0,
    averageLapTimeMinutes: 0,
    averageLapTimeSeconds: 0,
    averageLapTimeMilliSeconds: 0,
}

export const useFuelCalculator = () => {
    const [values, setValues] = useState<FuelForm>(FUEL_DEFAULT_VALUES);
    const [fuelCalculatedSafe, setFuelCalculatedSafe] = useState<Number>(0);
    const [fuelCalculatedRisk, setFuelCalculatedRisk] = useState<Number>(0);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (values) {
            setFuelCalculatedSafe(fuelFormulaSafe(values));
            setFuelCalculatedRisk(fuelFormulaRisk(values));
        }
    }

    return {
        values,
        handleOnSubmit,
        handleOnChange,
        fuelCalculatedSafe,
        fuelCalculatedRisk
    }
}