import React from 'react';
import './App.css';
import 'remixicon/fonts/remixicon.css';
import { FuelCalculatorForm } from './components/FuelCalculatorForm';

function App() {
  return (
    <div className='m-auto flex min-h-screen w-full max-w-2xl flex-col py-6 px-4 text-slate-900'>
      <header className='flex w-full flex-col gap-3'>
        <div className='flex w-full items-center mb-5'>
          <h1 className='text-3xl font-bold mr-1'>ACC</h1>
          <span className='flex-1 text-3xl text-slate-400 font-light'>tools</span>
        </div>
      </header>
      <main className='flex w-full flex-col gap-3'>
        <h2 className='text-2xl font-bold mr-1'>&#9981; Fuel calculator</h2>
        <FuelCalculatorForm/>
      </main>
      <div className='fixed right-4 bottom-4'>
        <a className='social' href='https://www.reddit.com/user/Racc00nNL' target="_blank"><i className="ri-reddit-fill"></i></a>
      </div>
    </div>
  );
}

export default App;
