import { FuelForm } from "../types";

export function fuelFormulaSafe({durationHours, durationMinutes, perLap, averageLapTimeMinutes, averageLapTimeSeconds, averageLapTimeMilliSeconds} : FuelForm) {
    const averageLapTime = (averageLapTimeMinutes * 60) + Number(averageLapTimeSeconds) + (averageLapTimeMilliSeconds / 1000);
    const duration = (durationHours * 60) + Number(durationMinutes);
    console.log(duration)

    const fuelCalculationResult = ((duration * 60) / averageLapTime ) * perLap + (2 * perLap);

    return fuelCalculationResult;
}

export function fuelFormulaRisk({durationHours, durationMinutes, perLap, averageLapTimeMinutes, averageLapTimeSeconds, averageLapTimeMilliSeconds} : FuelForm) {
    const averageLapTime = (averageLapTimeMinutes * 60) + Number(averageLapTimeSeconds) + (averageLapTimeMilliSeconds / 1000);
    const duration = (durationHours * 60) + Number(durationMinutes);
    
    const fuelCalculationResult = ((duration * 60) / averageLapTime ) * perLap;

    return fuelCalculationResult;
}