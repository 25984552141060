import { useFuelCalculator } from "../hooks/useFuelCalculator"
import { TextInput } from "./TextInput"

export const FuelCalculatorForm = () => {
    const {handleOnSubmit, handleOnChange, values, fuelCalculatedSafe, fuelCalculatedRisk} = useFuelCalculator();

    return (
        <div>
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleOnSubmit}>

            <label className="block text-gray-700 text-sm font-bold mb-2">
                Duration of the race
            </label>
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 px-3 md:mb-4 sm:mb-0">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="durationHours">
                        Hours
                    </label>
                    <TextInput
                    name="durationHours"
                    type="number"
                    placeholder="2"
                    required={false}
                    onChange={handleOnChange}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-4 sm:mb-0">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="durationMinutes">
                        Minutes<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                    name="durationMinutes"
                    type="number"
                    placeholder="20"
                    max="59"
                    required={true}
                    onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="perLap">
                    Average fuel per lap<span className="text-red-500">*</span>
                </label>
                <TextInput
                name="perLap"
                type="number"
                step=".01"
                placeholder="3.0"
                required={true}
                onChange={handleOnChange}
                />
            </div>

            <label className="block text-gray-700 text-sm font-bold mb-1" htmlFor="averageLapTime">
                Average lap time
            </label>
            <p className="text-sm text-slate-400 font-light mb-3">Note: You need to fill in all 3 fields for the average time</p>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/3 px-3 md:mb-6 sm:mb-0">
                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                        Minutes<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                    name="averageLapTimeMinutes"
                    type="number"
                    placeholder="1"
                    required={true}
                    onChange={handleOnChange}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3">
                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                        Seconds<span className="text-red-500">*</span>
                    </label>           
                    <TextInput
                        name="averageLapTimeSeconds"
                        type="number"
                        max="59"
                        placeholder="29"
                        required={true}
                        onChange={handleOnChange}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3">
                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                        Milliseconds<span className="text-red-500">*</span>
                    </label>           
                    <TextInput
                        name="averageLapTimeMilliSeconds"
                        type="number"
                        max="999"
                        step="1"
                        placeholder="534"
                        required={true}
                        onChange={handleOnChange}
                    />
                </div>
            </div>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" type="submit">Calculate</button>
            </form>

            <h2 className='text-2xl mt-10 font-bold mr-1 text-slate-400 font-light'>Fuel needed: <span className="text-2xl text-black font-bold mr-1">{fuelCalculatedSafe.toFixed(0).toString()}L</span>(safe) or <span className="text-2xl text-black font-bold mr-1">{fuelCalculatedRisk.toFixed(0).toString()}L</span>(Risk)</h2>
            <p className="mt-4 text-slate-400 font-light">If you want to be sure that you will make the finish line, the safe option is the best choice. This is because the safe options adds two extra laps of fuel on top of the minimum fuel needed to finish the race</p>
        </div>
    )
}